import React from 'react'
// import { Row, Col } from 'antd';
// import ExternalContent from "../ExternalContent";

class ContentWithMedia extends React.Component {
    element = false;

    constructor(props) {
        super(props);
        this.el = React.createRef();
    }

    componentDidMount() {
        let images = this.el.current.querySelectorAll('img');
        for (let index = 0; index < images.length; index++) {
            let img = images[index];
            if (img.getAttribute('src').startsWith('/wp-content/uploads/')) {
                img.src = process.env.CONTENT_API_PROTOCOL + '://' + process.env.CONTENT_API_URL + img.getAttribute('src');
            }
        };
    }

    render(){
        return <div ref={this.el} dangerouslySetInnerHTML={{__html: this.props.content}}/>;
    }
}

export default ContentWithMedia
